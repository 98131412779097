import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Read more about software development</h2>
      <p>
        Visit our blog to find out more about Python, Web Development, UX and Product Design and much more.
      </p>
      <ul className="actions">
        <li>
          <a href="https://stxnext.com/blog/" className="button">
            STX Next Blog
          </a>
        </li>
      </ul>
    </section>
    <section>
      <h2>Contact us</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>Mostowa 38 &bull; 61-854 Poznań &bull; Poland</dd>
        <dt>Phone</dt>
        <dd>+48 512 914 236</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:business@stxnext.com">business@stxnext.com</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/STXNext"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/StxNext" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/stx_next/" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/stxnext/"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://dribbble.com/STXNext" className="icon fa-dribbble alt">
            <span className="label">Dribbble</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy; Pythonvs.com. Design: <a href="https://html5up.net" rel="nofollow">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
